import React, { useState } from "react";
//CSS
import layout from "../../../../Modules/layout.module.css";
import styles from "./CreateImprovementQuestionSectionOption.module.css";
import Checkbox from "../../../Utils/Checkbox/Checkbox";
//Components
//Data

type CreateImprovementQuestionSectionOptionProps = {
  title?: string
};

const CreateImprovementQuestionSectionOption = ({ title }: CreateImprovementQuestionSectionOptionProps) => {

    const [isSelected, setIsSelected] = useState<boolean>(false);

    return (
      <div className={`${styles.container} ${layout.row1} ${isSelected && styles.checked}`}>
        <div className={`${styles.left} ${layout.row1} ${layout.centered}`}>
          <Checkbox clickHandler={setIsSelected}/>
          <p>{title ? title : 'Option title here'}</p>
        </div>
        <div className={`${styles.right} ${layout.row} ${layout.centered} ${layout.justifyFlexEnd}`} onClick={() => {
            console.log("View areas for improvement clicked.")
        }}>
            View Areas for Improvement
        </div>
      </div>
    );
  };

export default CreateImprovementQuestionSectionOption;
