import React, { useState } from 'react';
import styles from './FloatingLabelInput.module.css';

type FloatingLabelInputProps = {
  placeholder?: string;
  label: string;
  required?: boolean;
  onChange?: (value: string) => void;
};

const FloatingLabelInput = ({
  placeholder = '',
  label,
  required = false,
  onChange,
}: FloatingLabelInputProps) => {
  const [value, setValue] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setValue(newValue);
    onChange?.(newValue);
  };

  return (
    <div className={styles.floatingLabelInput}>
      <label className={styles.floatingLabel}>
        {label}
        {required && <span className={styles.asterisk}>*</span>}
      </label>
      <input
        className={styles.input}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        required={required}
      />
    </div>
  );
};

export default FloatingLabelInput;
