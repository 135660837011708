import styles from "./QuestionSectionCriteria.module.css";
import layout from "../../Modules/layout.module.css";
import QuestionSectionTextArea from "./QuestionSectionTextArea";
import { IQuestionnaireSection } from "../../Types/Products/IQuestionnaireSection";
import { useEffect, useState } from "react";
import Skeleton from "../Utils/Skeleton/Skeleton";
import { GenerateAssessmentByQuestionnaireSectionIdAndCriteriaId } from "../../API/Evaluate/Evaluate";
import { ICriteriaItem } from "../../Types/Products/ICriteriaItemData";
import {
  selectSectionById,
  selectCriteriaById,
  generateAssessment,
  getQuestionnaireByAreaId,
} from "../../Redux/Store/Slices/questionnaire";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Store";
import Button from "../Utils/Button/Button";
import { useNavigate, useParams } from "react-router-dom";

const QuestionSectionResults = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { areaId } = useParams();

  const { activeCriteriaId, activeSectionId, questionnaire } = useAppSelector(
    (state) => state.questionnaire
  );

  const section = useAppSelector(selectSectionById(activeSectionId));
  const criteria = useAppSelector(
    selectCriteriaById(activeSectionId, activeCriteriaId)
  );

  useEffect(() => {
    const init = async () => {
      if(!questionnaire?.legacyVersion){
      await dispatch(generateAssessment());
      }
      setIsLoading(false);
    };

    init();
  }, []);

  const fieldContentChangedHandler = async (fieldId: string, value: string) => {

  };

  return (
    <section className={layout.column2}>
      {
        criteria?.fields?.map(field => {
          return(
            <div className={layout.column1}>
              <h5>{field.title}</h5>
              <Skeleton loading={isLoading}>
                <QuestionSectionTextArea
                  onChangeHandler={(value) => fieldContentChangedHandler(field.id, value)}
                  className={styles.answer}
                  placeholder="Answer..."
                  value={field.content}
                />
              </Skeleton>
            </div>
          )
        })
      }
    </section>
  );
};

export default QuestionSectionResults;
