import React from "react";
//CSS
import styles from "./CreateImprovement.module.css";
import layout from "../../../../Modules/layout.module.css";
import Button from "../../../../Components/Utils/Button/Button";
import Form from "../../../../Components/Utils/Form/Form";
import CreateImprovementQuestionSection from "../../../../Components/CreateImprovement/CreateImprovementQuestionSection/CreateImprovementQuestionSection";
import FloatingLabelInput from "../../../../Components/Utils/Form/FloatingLabelInput/FloatingLabelInput";
import FloatingLabelSelect from "../../../../Components/Utils/Form/FloatingLabelSelect/FloatingLabelSelect";
//Components
//Data

type CreateImprovementProps = object;

const CreateImprovement = ({  }: CreateImprovementProps) => {

  const options = [
    {value: "Improvement Plan 1", label: "Option 1"},
    {value: "Improvement Plan 2", label: "Option 2"},
    {value: "Improvement Plan 3", label: "Option 3"},
    {value: "Improvement Plan 4", label: "Option 4"}
  ]

  const handleSelectChange = (value: string) => {
    console.log('Selected:', value);
  };

  return (
    <div className={layout.column}>
      <div className={layout.rowSpan}>
        <h2>Improvement Plan</h2>
      </div>
      <Form>
          <FloatingLabelInput placeholder="Add Objective Title..." label="What is the title of your objective? (e.g. Complete training for good practice)" required={true} />
          <FloatingLabelSelect
            label="Which Improvement plan would you like to add the objective to?"
            options={options}
            required
            onChange={handleSelectChange}
      />
      </Form>
      <CreateImprovementQuestionSection title="Management and internal communications" openOnLoad />
      <CreateImprovementQuestionSection title="Systems and structures" />
      <CreateImprovementQuestionSection title="Employer and stakeholder"/>
      <div className={`${layout.rowSpan} ${layout.justifyFlexEnd}`}>
        <Button>Create Improvement</Button>
      </div>
    </div>
  );
};

export default CreateImprovement;
