import React, { ReactNode } from 'react';
//CSS
import styles from './Form.module.css';
import layout from '../../../Modules/layout.module.css';
//Components
//Data

type FormProps = {
  children: ReactNode
};

const Form = ({ children } : FormProps) => {
  return (
    <form className={`${layout.column1} ${styles.container}`}>
      {children}
    </form>
  );
};

export default Form;