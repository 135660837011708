import { createAsyncThunk, createSlice, PayloadAction, Update } from "@reduxjs/toolkit"
import axios from "axios";
import { IProductQuestionnaire } from "../../../Types/Products/IProductQuestionnaire";
import { axiosInstance } from "../../../Authentication/AxiosInterceptor";
import { IQuestionnaireQuestion } from "../../../Types/Products/IQuestionnaireQuestion";
import { IQuestionnaireSection } from "../../../Types/Products/IQuestionnaireSection";
import { getCriteriaIndex, getCriteriaIndexById, getQuestionIndex, getSectionIndex, getSectionIndexById, ICriteriaItem } from "../../../Types/Products/ICriteriaItemData";
import { IGrade } from "../../../Types/IGrade";
import { RootState } from "../Store";
import { ICompletionStatus } from "../../../Types/ICompletionStatus";
import { stat } from "fs";
import { createSelector } from '@reduxjs/toolkit';
import { useRef } from "react";
import { IQuestionnaireSectionResults } from "../../../Types/Products/IQuestionnaireSectionResults";
import { IDynamicField } from "../../../Types/Products/IDynamicField";

let updateTimer: NodeJS.Timeout | null = null; // Declare the timer variable

const debounceDelay = 500;

interface QuestionnaireState {
    questionnaire: IProductQuestionnaire | undefined,
    activeSectionId: string,
    activeCriteriaId: string,
}

const initialState: QuestionnaireState = {
    questionnaire: undefined,
    activeSectionId: "",
    activeCriteriaId: "",
}

export interface UpdateQuestionnaireSectionStatusPayload{
    sectionId: string,
    status: ICompletionStatus
}

// Using axios and try...catch in createAsyncThunk
export const getQuestionnaireByAreaId = createAsyncThunk("questionnaire/GetQuestionnaireByAreaId", async (areaId: string, thunkApi) => {
    const response = await axiosInstance.get(`GetQuestionnaireByAreaId/${areaId}`);
    return response.data as IProductQuestionnaire;
});

export const updateQuestionnaireQuestion = createAsyncThunk("questionnaire/UpdateQuestionnaireQuestion", async (question: IQuestionnaireQuestion, thunkApi) => {
    const {questionnaire} = thunkApi.getState() as RootState;

    const update = async () => {
        if (updateTimer !== null) {
            clearTimeout(updateTimer); // Clear the existing timer
        }

        new Promise<IQuestionnaireQuestion>((resolve, reject) => {
            updateTimer = setTimeout(async () => {
                try {
                    const response = await axiosInstance.post(`UpdateQuestionnaireQuestion`, question);
                    resolve(question); // Resolve the promise with question data
                } catch (error) {
                    reject(error); // Reject the promise in case of error
                }
            }, debounceDelay);
        });
    };

    update();

    return question;
});

export const generateAssessment = createAsyncThunk("questionnaire/GenerateAssessment", async (_, thunkApi) => {
    const {questionnaire} = thunkApi.getState() as RootState;
    const {activeSectionId, activeCriteriaId} = questionnaire;

    const response = await axiosInstance.get(`GenerateAssessmentByQuestionnaireSectionIdAndCriteriaId/${activeSectionId}/${activeCriteriaId}`);
  
    return response.data as IDynamicField[];
});

export const updateQuestionnaireGrade = createAsyncThunk("questionnaire/UpdateQuestionnaireGrade", async (grade: IGrade, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const questionnaireId = state.questionnaire.questionnaire?.id;
    if(questionnaireId){
        const response = await axiosInstance.patch(`UpdateQuestionnaireGrade/${questionnaireId}/${grade}`);
    }
    return grade as IGrade;
})

export const updateCriteriaGrade = createAsyncThunk("questionnaire/UpdateCriteriaGrade", async (grade: IGrade, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const {activeCriteriaId, questionnaire, activeSectionId} = state.questionnaire;

    const sectionIndex = getSectionIndexById(questionnaire!.questionnaireSections, activeSectionId);
    const activeCriteria = questionnaire?.questionnaireSections[sectionIndex].criteria.find(criteriaItem => criteriaItem.id === activeCriteriaId);    
    
    if(activeCriteria){
        activeCriteria.grade = grade;
        const response = await axiosInstance.patch(`UpdateCriteriaGrade/${activeCriteriaId}/${grade}`);
    }

    return grade as IGrade;
});

export const updateQuestionnaireSectionStatus = createAsyncThunk("questionnaire/UpdateQuestionnaireSectionStatus", async (payload: UpdateQuestionnaireSectionStatusPayload, thunkApi) => {
    const response = await axiosInstance.patch(`UpdateQuestionnaireSectionStatus/${payload.sectionId}/${payload.status}`);
    return payload as UpdateQuestionnaireSectionStatusPayload;
});

export const QuestionnaireSlice = createSlice({
    name: "Questionnaire",
    initialState,
    reducers:{
        setQuestionnaireSection:(state, action: PayloadAction<{section: IQuestionnaireSection}>) => {
            const {section} = action.payload;
            const index = getSectionIndexById(state.questionnaire!.questionnaireSections, section.id);
            state.questionnaire!.questionnaireSections[index] = section;
        },
        setActiveQuestionnaireSection:(state, action: PayloadAction<{sectionId: string}>) => {
            state.activeSectionId = action.payload.sectionId;
        },
        setActiveCriteria:(state, action: PayloadAction<{itemId: string}>) => {
            state.activeCriteriaId = action.payload.itemId;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(getQuestionnaireByAreaId.fulfilled, (state, action) => {
            state.questionnaire = action.payload;
        });
        
        builder.addCase(updateQuestionnaireQuestion.fulfilled, (state, action) => {

            if (!state.questionnaire) {
                return;
            }

            const stateQuestion = action.payload as IQuestionnaireQuestion;
            const sections = state.questionnaire.questionnaireSections as IQuestionnaireSection[];

            const sectionIndex = getSectionIndex(sections, stateQuestion);
            if(sectionIndex === -1){
                return;
            }
            const section = sections[sectionIndex];
            const criteriaIndex = getCriteriaIndex(section, stateQuestion);
            if(criteriaIndex === -1){
                return;
            }
            const criteria = section.criteria[criteriaIndex];
            const questionIndex = getQuestionIndex(criteria, stateQuestion);
            
            state.questionnaire.questionnaireSections[sectionIndex].criteria[criteriaIndex].questions[questionIndex] = action.payload;
        });

        builder.addCase(updateQuestionnaireGrade.fulfilled, (state, action) => {

            if (!state.questionnaire) {
                return;
            }

            const grade = action.payload as IGrade;
            
            state.questionnaire.overallGrade = grade;
        });

        builder.addCase(updateQuestionnaireSectionStatus.fulfilled, (state, action) => {

            if (!state.questionnaire) {
                return;
            }

            const payload = action.payload as UpdateQuestionnaireSectionStatusPayload;
            const sections = state.questionnaire.questionnaireSections as IQuestionnaireSection[];

            const sectionIndex = getSectionIndexById(sections, payload.sectionId);

            state.questionnaire.questionnaireSections[sectionIndex].completionStatus = payload.status;
        });

        builder.addCase(generateAssessment.fulfilled, (state, action) => {

            if (!state.questionnaire) {
                return;
            }

            const fields = action.payload as IDynamicField[];
            const sections = state.questionnaire.questionnaireSections as IQuestionnaireSection[];

            const sectionIndex = getSectionIndexById(sections, state.activeSectionId);
            const section = sections[sectionIndex];
            const criteriaIndex = getCriteriaIndexById(section, state.activeCriteriaId);

            state.questionnaire.questionnaireSections[sectionIndex].criteria[criteriaIndex].fields = fields;
        });
    }
})

export const selectSectionById = (sectionId: string) => createSelector(
  [(state: RootState) => state.questionnaire?.questionnaire?.questionnaireSections],
  (questionnaireSections = []) => questionnaireSections.find(section => section.id === sectionId)
);

export const selectCriteriaById = (sectionId: string, criteriaId: string) => createSelector(
  [selectSectionById(sectionId)],
  (section) => section?.criteria.find(criteria => criteria.id === criteriaId)
);

export const selectQuestionById = (sectionId: string, criteriaId: string, questionId: string) => createSelector(
  [selectCriteriaById(sectionId, criteriaId)],
  (criteria) => criteria?.questions.find(question => question.id === questionId)
);

export default QuestionnaireSlice.reducer;
export const {setQuestionnaireSection, setActiveCriteria, setActiveQuestionnaireSection} = QuestionnaireSlice.actions;