import React, { useState } from "react";
import styles from "./FloatingLabelSelect.module.css";

type Option = {
  value: string;
  label: string;
};

type FloatingLabelSelectProps = {
  label: string;
  options: Option[];
  required?: boolean;
  onChange?: (value: string) => void;
};

const FloatingLabelSelect: React.FC<FloatingLabelSelectProps> = ({
  label,
  options,
  required = false,
  onChange,
}) => {
  const [value, setValue] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.currentTarget.value;
    setValue(selectedValue);
    onChange?.(selectedValue);
  };

  return (
    <div className={styles.floatingLabelSelect}>
      <label className={styles.floatingLabel}>
        {label}
        {required && <span className={styles.asterisk}>*</span>}
      </label>
      <select
        className={styles.select}
        value={value}
        onChange={handleChange}
        required={required}
      >
        {/* Placeholder option */}
        <option value="" disabled hidden>
          Select an option
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FloatingLabelSelect;
