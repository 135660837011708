import { useAuth0 } from '@auth0/auth0-react'
import { Routes, Route } from 'react-router-dom';
import './App.css';
import './Tour.css';
//Components
import Navbar from './Components/Navbar/Navbar';
import AuthLogin from './authLogin';
import { useTour } from '@reactour/tour'
import SearchBar from './Components/SearchBar/SearchBar';
import Button from './Components/Utils/Button/Button';
import NotificationItem from './Components/Notifications/NotificationItem';
import EvaluateArea from './Routes/Evaluate/EvaluateArea/EvaluateArea';
import RouteWrapper from './Components/Utils/RouteWrapper/RouteWrapper';
//Routes
import Dashboard from './Routes/Dashboard';
import Evaluate from './Routes/Evaluate/Evaluate';
import Improve from './Routes/Improve'
import Develop from './Routes/Develop';
import Library from './Routes/Library';
import Resources from './Routes/Resources';
import Settings from './Routes/Settings';
import Account from './Routes/Account';
import QualityCalendar from './Routes/QualityCalendar';
import AnalyseBot from './Routes/AnalyseBot/AnalyseBot';
import Portal from './Routes/Portal/Portal';
import QuestionnaireEditor from './Routes/Portal/QuestionnaireEditor/QuestionnaireEditor';
import TemplateView from './Routes/Portal/QuestionnaireEditor/TemplateView/TemplateView';
import { DrawerProvider } from './Components/SectionsDrawer/DrawerContext';
import ReduxTest from './Routes/ReduxTest';

function App() {

  const { isAuthenticated, user, logout } = useAuth0();

  const { setIsOpen } = useTour();

  const data = ["Evalute", "Improve", "Develop", "Library", "Resources", "Settings", "Admin"]

  if (isAuthenticated) {
    return (
      <div className='wrapper'>
        <Navbar />
        <div className='container'>
          <div className='top-nav'>
            <div className='top-nav__left'>
              <SearchBar type='' data={data} />
            </div>
            <div className='top-nav__right'>
              <Button className='evaluate-tour-1' clickHandler={() => logout()} size="Icon" colour={"rgba(0,0,0,0"}>
                <img src='/img/NavIcons/logout.svg' />
              </Button>
              <Button className='tour-button-container' clickHandler={() => setIsOpen(true)} size="Icon" colour={"rgba(0,0,0,0"}>
                <img src='/img/NavIcons/product.svg' />
              </Button>
              <NotificationItem />
            </div>
          </div>
          <div className="main">
            <RouteWrapper>
              <Routes>

                <Route path="/" element={<Dashboard />} index />
                <Route path="/calendar" element={<QualityCalendar />} />

                <Route path="/evaluate" element={<Evaluate />}>
                  <Route path="" />
                  <Route path="planned" />
                  <Route path="archive" />
                </Route>

                  <Route path="evaluate/:areaId/*" element={<EvaluateArea />}>
                  </Route>

                <Route path="/portal/questionnaire-editor" element={<QuestionnaireEditor />} />
                
                  <Route path="/portal/questionnaire-editor/:templateId/*" element={<TemplateView />} />
      

                

                <Route path="/improve" element={<Improve />} />
                <Route path="/develop" element={<Develop />} />
                <Route path="/library" element={<Library />} />
                <Route path="/resources" element={<Resources />} />
                <Route path="/portal" element={<Portal />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/account" element={<Account />} />
                <Route path="/analyse-bot" element={<AnalyseBot />} />

              </Routes>
            </RouteWrapper>
          </div>
        </div>
      </div >
    );
  } else {
    return <AuthLogin />
  }

}

export default App;
