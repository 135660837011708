import React, { useState } from 'react';
//CSS
import styles from './Checkbox.module.css';
//Components
//Data

type CheckboxProps = {
    clickHandler: (x: boolean) => void
};

const Checkbox = ({ clickHandler } : CheckboxProps) => {

const [isChecked, setIsChecked] = useState<boolean>(false);

  return (
    <div
        className={`${styles.container} ${isChecked ? styles.checked : ''}`}
        onClick={() => {clickHandler(!isChecked); setIsChecked(!isChecked); }}
    >
      <img src='/img/tick.svg' />
    </div>
  );
};

export default Checkbox;