import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import styles from './EvaluateArea.module.css';
import layout from '../../../Modules/layout.module.css'
import SectionsDrawer from '../../../Components/SectionsDrawer/SectionsDrawer';
import { IProductQuestionnaire } from '../../../Types/Products/IProductQuestionnaire';
import QuestionSectionCriteria from '../../../Components/Products/QuestionSectionCriteria';
import { IDrawerItem, IDrawerSection } from '../../../Types/Drawer/ISectionsDrawerItem';
import { DrawerProvider } from '../../../Components/SectionsDrawer/DrawerContext';
import { IGrade } from '../../../Types/IGrade';
import FractionalBar from '../../../Components/Utils/FractionalBar/FractionalBar';
import GradeSelector from '../../../Components/Utils/GradeSelector/GradeSelector';
import { IDropDownListItem } from '../../../Types/DropDownList/IDropDownListItem';
import { useAppDispatch, useAppSelector } from '../../../Redux/Store/Store';
import { getQuestionnaireByAreaId, updateCriteriaGrade, updateQuestionnaireGrade } from '../../../Redux/Store/Slices/questionnaire';
import DropDownContainer from '../../../Components/Utils/DropDownContainer/DropDownContainer';
import CreateImprovement from './CreateImprovement/CreateImprovement';

const EvaluateArea = () => {

  const { areaId } = useParams();
  var basePath = `/evaluate/${areaId}`

  const dispatch = useAppDispatch();
  const questionnaire = useAppSelector(state => state.questionnaire.questionnaire)

  var [drawerSections, setDrawerSections] = useState<IDrawerSection[]>();

  const navigate = useNavigate();

  useEffect(() => {

    dispatch(getQuestionnaireByAreaId(areaId!))

    basePath = `/evaluate/${areaId}`

  }, [areaId]);

  useEffect(() => {
    if(questionnaire){
      const sections: IDrawerSection[] = questionnaire.questionnaireSections.map((section) => {
        const drawerSection: IDrawerSection = {
          id: section.id,
          title: section.title,
          status: section.completionStatus,
          items: section.criteria.map(item => ({
            id: item.id,
            title: item.title,
            url: `/${section.id}/${item.id}`
          }))
        }
        return drawerSection
      })
      setDrawerSections(sections);
    }

    console.log("Updated questionnaire")

    console.log(`overall grade: ${questionnaire?.overallGrade}`)

  }, [questionnaire]);  // This will run whenever 'questionnaire' changes.


  const getGrades = (questionnaireObject: IProductQuestionnaire | undefined) => {
    const gradeCountMap: { [key: string]: number } = {};
  
    questionnaireObject?.questionnaireSections.forEach((section: any) => {
      section?.criteria.forEach((criteriaItem: any) => {
        const grade = criteriaItem.results?.grade || "Not Set";
        gradeCountMap[grade] = (gradeCountMap[grade] || 0) + 1;
      });
    });
  
    return Object.entries(gradeCountMap).map(([grade, count]) => ({
      grade: grade as IGrade,
      count
    }));
  };


  if (drawerSections && questionnaire) {
    return (
      <div className={layout.row}> 
          <div className={`${layout.column} ${layout.container}`}>
            <div className={layout.rowSpan}>
              <h2>{questionnaire?.title}</h2>
              <img className={layout.icon} src='/img/gear.svg' />
            </div>

            <div className={layout.rowSpan40}>
              <div className={layout.column}>
                  <p>Grade for this area</p>
                  <FractionalBar items={getGrades(questionnaire)}></FractionalBar>
              </div>
              <div style={{marginLeft: '40px'}}>
                <GradeSelector onChange={(grade) => dispatch(updateQuestionnaireGrade(grade.text as IGrade))} grade={questionnaire.overallGrade}/>
              </div>
            </div>
           
            <div className={`${layout.lightBlue} ${layout.rowSpan} ${layout.centered}`}>
              <DropDownContainer title='Objectives and Key Results'>This is content</DropDownContainer>
            </div>
            {
            <Routes>
                  <Route path="create-improvement" element={<CreateImprovement/>} />
                  <Route path=":sectionId/:itemId/*" element={<QuestionSectionCriteria />}/>
            </Routes>
            }
          </div>
          <div className={styles.right}>
            <DrawerProvider>
              <SectionsDrawer sections={drawerSections} basePath={basePath} initialActiveItem={drawerSections[0].items[0].id}></SectionsDrawer>
            </DrawerProvider>
          </div>
      </div>
    )
  } else {
    return <></>
  }
}

export default EvaluateArea