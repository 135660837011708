import React, { useState } from "react";
//CSS
import layout from "../../../Modules/layout.module.css";
import styles from "./CreateImprovementQuestionSection.module.css";
import CreateImprovementQuestionSectionOption from "./CreateImprovementQuestionSectionOption/CreateImprovementQuestionSectionOption";
//Components
//Data

type CreateImprovementQuestionSectionProps = {
  title?: string,
  openOnLoad?: boolean
};

const CreateImprovementQuestionSection = ({ title, openOnLoad }: CreateImprovementQuestionSectionProps) => {

    const [expanded, setExpanded] = useState<Boolean>(openOnLoad ? true : false);

    return (
      <div className={`${layout.column} ${styles.container}`}>
        <div className={`${layout.rowSpan} ${styles.top}`} onClick={() => setExpanded(!expanded)}>
          <h2>{title ? title : 'Question Section Title'}</h2>
          <div className={`${styles.arrow} ${expanded && styles.expanded}`}>
            <img src="/img/down-arrow.svg" />
          </div>
        </div>
        {expanded && (
          <div className={styles.body}>
            <CreateImprovementQuestionSectionOption title="Managerial Capacity" />
            <CreateImprovementQuestionSectionOption title="Communication" />
            <CreateImprovementQuestionSectionOption title="Ensuring Capability" />
            <CreateImprovementQuestionSectionOption title="Finance" />
          </div>
        )}  
      </div>
    );
  };

export default CreateImprovementQuestionSection;
